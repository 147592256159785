import React from "react";
import "./WormyAnimation.scss";

function WormyAnimation() {
  return (
    <div className="wormy-animation">
      <div className="wormy-animation__worm" />
    </div>
  );
}

export { WormyAnimation };
