import React, { useRef, useEffect, useContext } from "react";
import { Logo } from "components/atoms/Logo/Logo";

import { colors } from "helpers/theme-provider/definitions";
import "./Header.scss";
import { IconArrowLeft } from "components/atoms/IconArrowLeft";
import { GlobalStateContext } from "AppRouter";

import { getScrollbarWidth } from "helpers/getScrollbarWidth";
import { LanguageSwitch } from "components/atoms/LanguageSwitch/LanguageSwitch";

const BackBtnI18n = {
  en: "Back",
  de: "Zurück",
  fr: "Retour",
  sl: "Nazaj",
};

const HeaderTheme = {
  DARK: "dark",
  LIGHT: "light"
};

function Header({ theme = HeaderTheme.LIGHT, history }) {
  const componentEl = useRef(null);
  const innerEl = useRef(null);

  const {
    headerTheme,
    headerSolid,
    pageHasScrollbar,
    backbuttonVisible,
    language
  } = useContext(GlobalStateContext);

  useEffect(() => {
    if (headerTheme === HeaderTheme.LIGHT) {
      componentEl.current.style.setProperty(`--defaultColor`, colors.white000);
    } else {
      componentEl.current.style.setProperty(`--defaultColor`, colors.blue600);
    }
  }, [headerTheme]);

  useEffect(() => {
    if (pageHasScrollbar) {
      innerEl.current.style.width = `calc(100% - ${getScrollbarWidth()}px)`;
      innerEl.current.style.maxWidth = `calc(2560px - ${getScrollbarWidth()}px)`;
    } else {
      innerEl.current.style.removeProperty("width");
      innerEl.current.style.removeProperty("maxWidth");
    }
  }, [pageHasScrollbar]);

  function renderBackBtn() {
    return (
      <div className="header__back-btn" onClick={history.goBack}>
        <IconArrowLeft variant="default" />
        {BackBtnI18n[language] || BackBtnI18n["en"]}
      </div>
    );
  }

  return (
    <header
      ref={componentEl}
      className={`header ${headerSolid ? "header--solid" : ""}`}
    >
      <div className="header__inner" ref={innerEl}>
        <nav className="header__meta-nav">
          {backbuttonVisible ? renderBackBtn() : <div />}

          <LanguageSwitch />
        </nav>
        <div className="header__moving-frame">
          <Logo className="header__logo" />
        </div>
      </div>
    </header>
  );
}

export { Header, HeaderTheme };
