import React, { useRef } from "react";
import { useRecipe } from "helpers/customHooks";
import "./Tooltip.scss";

function Tooltip({ children }) {
  const tooltipEl = useRef(null);
  const tooltipBg = useRef(null);
  const txtEl = useRef(null);
  useRecipe(tooltipEl, "image", "colorRecipe450");
  useRecipe(tooltipBg, "image", "colorRecipe450");
  useRecipe(txtEl, "image", "colorRecipe750");

  return (
    <div ref={tooltipEl} className="tooltip">
      <div ref={tooltipBg} className="tooltip__bg">
        <span ref={txtEl} className="tooltip__text">
          {children}
        </span>
      </div>
    </div>
  );
}

export { Tooltip };
