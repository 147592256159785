import React from "react";
import { NavLink } from "react-router-dom";

import "./NaviLink.scss";

function NaviLink({ children, to, fontSize = "static-0250", page }) {
  const styles = {
    fontSize: `var(--fontsize-${fontSize})`,
    lineHeight: `var(--lineheight-${fontSize})`,
  };

  function checkIsActive(match, location) {
    const urlPortions = location.pathname.split("/").filter(i => Boolean(i));
    const childAliases = [page.alias, ...getChildAliases(page.children)];
    return Boolean(urlPortions.find(portion => childAliases.includes(portion)));
  }

  function getChildAliases(children) {
    if (!children) return [];
    return Object.keys(children).map(childKey => {
      return children[childKey].alias;
    });
  }

  return (
    <NavLink
      className="deepdive-navi__link label"
      to={to}
      activeClassName="deepdive-navi__link--active"
      style={styles}
      isActive={checkIsActive}
    >
      {children}
    </NavLink>
  );
}

export { NaviLink };
