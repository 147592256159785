import { useEffect } from "react";
import { getRecipe } from "./theme-provider/provider";

function useRecipe(ref, theme, recipeName) {
  const recipe = getRecipe(theme, recipeName);

  useEffect(() => {
    if (!ref) throw Error("no reference given for custom hook useRecipe!");

    for (const colorKey in recipe) {
      ref.current.style.setProperty(`--${colorKey}Color`, recipe[colorKey]);
    }
  }, [recipe, ref]);
}

export { useRecipe };
