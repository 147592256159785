import { DetailPage } from "components/pages/DetailPage/DetailPage";
import { OverviewPage } from "components/pages/OverviewPage/OverviewPage";
import { Regular } from "components/pages/RegularPage/RegularPage";
import { IntroPage } from "components/pages/IntroPage/IntroPage";
import { InteractiveMapPage } from "components/pages/InteractiveMapPage/InteractiveMapPage";

const Pagetypes = {
  DETAILPAGE: "detail",
  INTERACTIVEMAPPAGE: "interactivemap",
  INTROPAGE: "intro",
  OVERVIEWPAGE: "overview",
  REGULAR: "regular",
  SURVEYPAGE: "survey",
  MAP: "map",
};

const getPageComponent = pagetype => {
  switch (pagetype) {
    case Pagetypes.REGULAR:
      return Regular;
    case Pagetypes.DETAILPAGE:
      return DetailPage;
    case Pagetypes.OVERVIEWPAGE:
      return OverviewPage;
    case Pagetypes.INTROPAGE:
      return IntroPage;
    case Pagetypes.MAP:
      return InteractiveMapPage;
    default:
      return IntroPage;
  }
};

export { Pagetypes, getPageComponent };
