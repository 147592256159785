import React, { useRef, useEffect } from "react";
import { useRecipe } from "helpers/customHooks";
import "./NoMatch.scss";

function NoMatch() {
  const componentEl = useRef(null);
  useRecipe(componentEl, "main", "colorRecipe450");

  useEffect(() => {
    console.log("componentDidMount");
  }, []); // gets called only once if this array is empty

  return (
    <div ref={componentEl} className="nomatch">
      404 Page not found!
    </div>
  );
}

export { NoMatch };
