import React, { useRef, useState } from "react";
import { useRecipe } from "helpers/customHooks";
import "./Toggle.scss";

function Toggle({ cb, options, className }) {
  const componentEl = useRef(null);
  const togglerEl = useRef(null);
  useRecipe(componentEl, "image", "colorRecipe450");
  useRecipe(togglerEl, "image", "colorRecipe750");
  const [selected, setSelected] = useState(false);

  function handleClick() {
    setSelected(!selected);
    if (typeof cb === "function") {
      cb();
    }
  }

  return (
    <div
      ref={componentEl}
      className={`toggle ${selected ? "toggle--selected" : ""} ${className}`}
      onClick={handleClick}
    >
      <div className="toggle__option">{options[0].label}</div>
      <div className="toggle__bg">
        <div className="toggle__toggler" ref={togglerEl} />
      </div>
      <div className="toggle__option">{options[1].label}</div>
    </div>
  );
}

export { Toggle };
