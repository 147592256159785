import React from "react";
import "./Logo.module.scss";

const LogoSize = {
  LOGO100: "LOGO100",
  LOGO200: "LOGO200",
  LOGO300: "LOGO300",
  LOGO400: "LOGO400",
};

const LogoWidth = {
  LOGO100: "32px",
  LOGO200: "40px",
  LOGO300: "48px",
  LOGO400: "56px",
};

function Logo(props) {
  return React.createElement(
    "svg",
    {
      viewBox: "0 0 56 56",
      xmlns: "http://www.w3.org/2000/svg",
      fill: "currentColor",
      role: "img",
      className: `${props.className} logo`,
    },
    React.createElement("path", {
      d:
        "M28,52.7A24.62,24.62,0,0,1,4.8,19.9l14.4,28c.2.4.4.7.9.7s.7-.3.9-.7L27.7,33q.15-.3.3-.3c.2,0,.2.2.3.3l6.8,14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28A23.75,23.75,0,0,1,52.7,28,25,25,0,0,1,28,52.7Zm0-31.1c-.2,0-.2-.2-.3-.3L20,4.7a23.56,23.56,0,0,1,16,0L28.3,21.3C28.2,21.4,28.2,21.6,28,21.6Zm-8.1,18c-.2,0-.2-.2-.3-.3L7,15.1a25.31,25.31,0,0,1,9.1-8.7L25,25.6c.2.4.4.5.7.5h4.6c.3,0,.5-.1.7-.5L39.8,6.4a25.31,25.31,0,0,1,9.1,8.7L36.4,39.3q-.15.3-.3.3c-.2,0-.2-.2-.3-.3L31,28.7c-.2-.4-.4-.5-.7-.5H25.7c-.3,0-.5.1-.7.5L20.2,39.3C20.1,39.4,20.1,39.6,19.9,39.6ZM28,56A28,28,0,1,0,0,28,28,28,0,0,0,28,56Z",
    })
  );
}

export { Logo, LogoSize, LogoWidth };
