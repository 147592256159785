import React, { useRef } from "react";
import { useRecipe } from "helpers/customHooks";
import "./Headline.scss";

const HeadlineSizes = {
  small: "dynamic-0300",
  medium: "dynamic-0400",
  large: "dynamic-0500",
};

function Headline({
  theme = "main",
  colorRecipe = "colorRecipe100",
  className = "",
  children,
  headlineSize = "medium",
  fontSize,
  html,
}) {
  const componentEl = useRef(null);
  useRecipe(componentEl, theme, colorRecipe);

  const txtSize = fontSize || HeadlineSizes[headlineSize];

  const styles = {
    fontSize: `var(--fontsize-${txtSize})`, // var(--fontsize-static-0550)
    lineHeight: `var(--lineheight-${txtSize})`, // var(--fontsize-static-0550)
  };

  //TODO: build font-size checker
  if (html) {
    return (
      <span
        className={`headline ${className}`}
        ref={componentEl}
        style={styles}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return (
    <span className={`headline ${className}`} ref={componentEl} style={styles}>
      {children}
    </span>
  );
}

export { Headline };
