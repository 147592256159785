import React, { useRef } from "react";
import { useRecipe } from "helpers/customHooks";
import "./Hotspot.scss";
import { Tooltip } from "components/atoms/Tooltip/Tooltip";

function Hotspot({ x, y, label, contentId, onClickHandler, className }) {
  const componentEl = useRef(null);
  useRecipe(componentEl, "main", "colorRecipe450");

  const style = {
    left: `${x}%`,
    top: `${y}%`,
  };

  return (
    <div
      ref={componentEl}
      style={style}
      className={`hotspot ${className}`}
      onClick={() => onClickHandler({ contentId, x, y })}
    >
      <Tooltip>{label}</Tooltip>
    </div>
  );
}

export { Hotspot };
