import React from "react";
import "./MediaOnly.scss";

import { Media } from "components/atoms/Media/Media";

function MediaOnly({
  media,
  mediaDisclaimer,
  className,
  mediaPath,
  fullscreen
}) {
  const fullscreenClass = fullscreen ? "media-only--fullscreen" : "";

  return (
    <div className={`media-only ${fullscreenClass}`}>
      <Media
        className="media-only__media"
        mediaPath={mediaPath}
        media={media}
        mediaDisclaimer={mediaDisclaimer}
      />
    </div>
  );
}

export { MediaOnly };
