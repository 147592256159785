import React, { useRef, useContext } from "react";
import "./LanguageSwitch.scss";

import { GlobalStateContext } from "AppRouter";

function LanguageSwitch() {
  const componentEl = useRef(null);

  const { setLanguage, language } = useContext(GlobalStateContext);

  function handleClick(e) {
    setLanguage(e.currentTarget.dataset["value"]);
  }

  const langs = process.env.REACT_APP_LANGUAGES.split(', ');
  const langList = langs.map(function(lang, index) {
    return (
      <li
        key={index}
        data-value={lang}
        onClick={handleClick}
        className={`language-switch__lang ${language === lang ? "language-switch__lang--active" : ""}`}>
        {lang.toUpperCase()}
      </li>
    );
  });

  return (
    <ul className="language-switch" ref={componentEl}>
      {langList}
    </ul>
  );
}

export { LanguageSwitch };
