import { recipes, themes } from "./definitions";

function getRecipe(theme = "main", recipeName = "colorRecipe100") {
  if (!recipes.hasOwnProperty(theme)) {
    throw Error(
      "Theme not found! Chose on of the following: " + Object.keys(themes)
    );
  }
  if (!recipes[theme].hasOwnProperty(recipeName)) {
    recipeName = "colorRecipe100"; // default
  }
  return recipes[theme][recipeName];
}

export { getRecipe };
