import React, { useRef } from "react";
import { useRecipe } from "helpers/customHooks";
import "./Copy.scss";

function Copy({
  theme = "main",
  colorRecipe = "colorRecipe100",
  className = "",
  children,
  fontSize = "dynamic-0400",
  html
}) {
  const componentEl = useRef(null);
  useRecipe(componentEl, theme, colorRecipe);

  const styles = {
    fontSize: `var(--fontsize-${fontSize})`, // var(--fontsize-static-0550)
    lineHeight: `var(--lineheight-${fontSize})` // var(--fontsize-static-0550)
  };

  //TODO: build font-size checker
  if (html) {
    return (
      <span
        className={`copy ${className}`}
        ref={componentEl}
        style={styles}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return (
    <span className={`copy ${className}`} ref={componentEl} style={styles}>
      {children}
    </span>
  );
}

export { Copy };
