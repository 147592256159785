import React from "react";
function IconClose(props) {
  if (props.variant === "default") {
    return React.createElement(
      "svg",
      {
        title: props.title,
        viewBox: "0 0 24 24",
        width: "24",
        height: "24",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        role: "img",
      },
      React.createElement("path", {
        d:
          "M20.956 3.985l-.942-.941L12 11.059 3.985 3.044l-.941.941L11.059 12l-8.015 8.015.941.941L12 12.941l8.014 8.015.942-.941L12.941 12z",
      })
    );
  }
  if (props.variant === "small") {
    return React.createElement(
      "svg",
      {
        title: props.title,
        height: "12",
        viewBox: "0 0 12 12",
        width: "12",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        role: "img",
      },
      React.createElement("path", {
        d:
          "M6 5.113l-3.58-3.58-.887.887L5.113 6l-3.58 3.58.887.887L6 6.887l3.58 3.58.887-.887L6.887 6l3.58-3.58-.888-.887z",
      })
    );
  }
  return null;
}

export {IconClose};