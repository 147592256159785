import React, { useEffect, useState, useCallback, createContext } from "react";
import { useFetch } from "react-async";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { getPageComponent } from "helpers/pagetypes";
import { NoMatch } from "components/pages/NoMatch/NoMatch";
import { HeaderTheme } from "components/molecules/Header/Header";
import { LoadingSpinner } from "components/atoms/LoadingSpinner/LoadingSpinner";

const GlobalStateContext = createContext(null);
const SitemapContext = createContext(null);

function Index({ pages }) {
  return (
    <ul>
      {pages.map(page => (
        <li key={page.alias}>
          <Link to={`/${page.alias}/`}>{page.label}</Link>
        </li>
      ))}
    </ul>
  );
}

function AppRouter() {
  const [language, setLanguage] = useState(
    process.env.REACT_APP_DEFAULT_LANGUAGE
  );
  const [headerTheme, setHeaderTheme] = useState(HeaderTheme.LIGHT);
  const [pageHasScrollbar, setPageHasScrollbar] = useState(false);
  const [headerSolid, setHeaderSolid] = useState(false);
  const [backbuttonVisible, setBackbuttonVisible] = useState(false);
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState(false);
  const [contentPadded, setContentPadded] = useState(false);

  const globalContextValue = {
    headerTheme,
    setHeaderTheme,
    pageHasScrollbar,
    setPageHasScrollbar,
    headerSolid,
    setHeaderSolid,
    backbuttonVisible,
    setBackbuttonVisible,
    language,
    setLanguage,
    currentVideoPlaying,
    setCurrentVideoPlaying,
    contentPadded,
    setContentPadded
  };

  // °°°°°°°°°°°°°°°°°°°°°°°
  // Ajax
  // _______________________

  const jsonUrl = process.env.REACT_APP_LOKAL_FETCH
      ? `${process.env.PUBLIC_URL}/${language}/sitemap.json`
      : `${process.env.REACT_APP_CONTAO_URL}sitemap?language=${language}`;

  const headers = { Accept: "application/json" };
  const { data, error, isLoading } = useFetch(jsonUrl, {
    headers
  });

  // °°°°°°°°°°°°°°°°°°°°°°°
  // State
  // _______________________

  const [flatSitemap, setFlatSitemap] = useState(null);
  const [aliasMap, setAliasMap] = useState(null);

  // °°°°°°°°°°°°°°°°°°°°°°°
  // Effects
  // _______________________

  const flattenSitemap = useCallback(obj => {
    return Object.keys(obj).reduce((acc, key) => {
      const { children, ...page } = obj[key];
      acc[key] = page;
      if (obj[key].children) {
        acc = Object.assign({}, acc, flattenSitemap(obj[key].children));
      }
      return acc;
    }, {});
  }, []);

  const flattenAlias = useCallback(obj => {
    return Object.keys(obj).reduce((acc, key) => {
      const { alias } = obj[key];
      acc[alias] = key;
      if (obj[key].children) {
        acc = Object.assign({}, acc, flattenAlias(obj[key].children));
      }
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    if (data && !flatSitemap) {
      setFlatSitemap(flattenSitemap(data));
    }
    if (data && !aliasMap) {
      setAliasMap(flattenAlias(data));
    }
  }, [
    data,
    aliasMap,
    flatSitemap,
    setFlatSitemap,
    setAliasMap,
    flattenSitemap,
    flattenAlias
  ]);

  // °°°°°°°°°°°°°°°°°°°°°°°
  // Render
  // _______________________

  if (isLoading) return <LoadingSpinner />;
  if (error) return `Something went wrong: ${error.message}`;
  if (!aliasMap) return <LoadingSpinner />;
  if (!flatSitemap) return <LoadingSpinner />;

  if (data) {
    const sitemap = data;
    return (
      <Router>
        <GlobalStateContext.Provider value={globalContextValue}>
          <SitemapContext.Provider value={{ sitemap, flatSitemap, aliasMap }}>
            <>
              <Route
                path="/"
                exact
                render={() => <Index pages={Object.values(sitemap)} />}
              />
              <Route
                path="/:app"
                render={routerProps => {
                  const app = routerProps.match.params.app;

                  const pageId = aliasMap[app];
                  const page = flatSitemap[pageId];

                  if (!page) return <NoMatch />;

                  const PageComponent = getPageComponent(page.type);

                  return <PageComponent page={page} {...routerProps} />;
                }}
              />
            </>
          </SitemapContext.Provider>
        </GlobalStateContext.Provider>
      </Router>
    );
  }
}

export { AppRouter, SitemapContext, GlobalStateContext };
