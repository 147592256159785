import React from "react";
import "./EditorialTeaser.scss";

import { themes } from "helpers/theme-provider/definitions";

import {
  Button,
  ButtonAppearance,
  ButtonType,
} from "components/atoms/Button/Button";
import { Media } from "components/atoms/Media/Media";
import { Headline } from "components/atoms/Headline/Headline";

function EditorialTeaser({
  headline,
  headlineSize,
  subheadline,
  cta,
  mediaPath,
  className,
  aspectRatio,
  mediaSize,
  column,
  media,
  buttonTo = "id3-detail/1",
  background = false,
  mediaDisclaimer = "",
}) {
  return (
    <div className="editorial-teaser">
      {mediaPath && (
        <Media
          className={`editorial-teaser__media editorial-teaser__media--${mediaSize}`}
          mediaPath={mediaPath}
          media={media}
          mediaDisclaimer={mediaDisclaimer}
        />
      )}
      <div className="editorial-teaser__text">
        <Headline
          theme="main"
          colorRecipe="colorRecipe400"
          className="editorial-teaser__subheadline"
          html={subheadline}
          fontSize="dynamic-0200"
        />
        <Headline
          theme="main"
          colorRecipe="colorRecipe100"
          className="editorial-teaser__headline"
          html={headline}
          headlineSize={headlineSize}
        />
      </div>
      <Button
        className="editorial-teaser__button"
        appearance={ButtonAppearance.SECONDARY}
        theme={themes.MAIN}
        type={ButtonType.BUTTON}
      >
        {cta}
      </Button>
    </div>
  );
}

export { EditorialTeaser };
