import React, { useContext, useRef } from "react";
import { Route, Redirect } from "react-router-dom";

import { getPageComponent } from "helpers/pagetypes";
import { Header } from "components/molecules/Header/Header";
import { TabNavi } from "components/molecules/TabNavi/TabNavi";
import { NoMatch } from "components/pages/NoMatch/NoMatch";
import { SitemapContext } from "AppRouter";
import { GlobalStateContext } from "AppRouter";

function Regular({ match }) {
  const contentEl = useRef(null);

  const { sitemap, flatSitemap, aliasMap } = useContext(SitemapContext);
  const { contentPadded } = useContext(GlobalStateContext);

  return (
    <>
      <Route
        path={`${match.url}`}
        exact
        render={() => <Redirect to={`${match.url}${process.env.REACT_APP_START_PAGE}`} />}
      />
      <Route
        path={`${match.path}/:page/:id?`}
        render={routerProps => {
          const pageAlias = routerProps.match.params.page;

          // requested page
          let pageId = aliasMap[pageAlias];
          let appId = aliasMap[match.params.app];
          let page = flatSitemap[pageId];

          if (!page) return <NoMatch />;

          const PageComponent = getPageComponent(page.type);

          return (
            <>
              <div
                id="content"
                className={`content--${page.type} ${
                  contentPadded ? "content--padded" : ""
                }`}
                ref={contentEl}
              >
                <Header {...routerProps} />
                <PageComponent page={page} pageId={pageId} {...routerProps} />
              </div>
              <TabNavi linklist={sitemap[appId].children} match={match} />
            </>
          );
        }}
      />
    </>
  );
}

export { Regular };
