import React, { useState, useRef, useEffect } from "react";
import { useRecipe } from "helpers/customHooks";
import { themes } from "helpers/theme-provider/definitions";
import "./Button.scss";

import { NavLink } from "react-router-dom";

export const ButtonAppearance = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const ButtonType = {
  SUBMIT: "submit",
  RESET: "reset",
  BUTTON: "button",
  LINK: "link",
};

export function Button({
  type = ButtonType.BUTTON,
  appearance = ButtonAppearance.PRIMARY,
  theme = themes.MAIN,
  to = null,
  fontSize = "static-0200",
  ...props
}) {
  const bgEl = useRef(null);
  const textEl = useRef(null);

  let bgRecipe;
  let textRecipe;

  const [disabled, setDisabled] = useState(props.disabled);

  const labelStyles = {
    fontSize: `var(--fontsize-${fontSize})`,
    lineHeight: `var(--lineheight-${fontSize})`,
  };

  switch (appearance) {
    case ButtonAppearance.PRIMARY:
      bgRecipe = "colorRecipe450";
      textRecipe = "colorRecipe750";
      break;
    case ButtonAppearance.SECONDARY:
      bgRecipe = "colorRecipe450";
      textRecipe = "colorRecipe450";
      break;
    default:
      bgRecipe = "colorRecipe450";
      textRecipe = "colorRecipe750";
      break;
  }

  useRecipe(bgEl, theme, bgRecipe);
  useRecipe(textEl, theme, textRecipe);

  // if props.disabled changes
  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  function handleClick(e) {
    if (disabled) {
      e.preventDefault();
    }
  }

  function getClassNames() {
    const classNames = ["button"];
    if (disabled) classNames.push("button--disabled");
    if (appearance === ButtonAppearance.SECONDARY) {
      classNames.push("button--secondary");
    }

    return classNames.join(" ");
  }

  if (type === ButtonType.LINK) {
    return (
      <NavLink to={to}>
        <div ref={bgEl} className={getClassNames()}>
          <div className="button__text label" ref={textEl} style={labelStyles}>
            {props.children}
          </div>
        </div>
      </NavLink>
    );
  }

  return (
    <button
      className={getClassNames()}
      type={type}
      onClick={handleClick}
      ref={bgEl}
    >
      <div className="button__text label" ref={textEl} style={labelStyles}>
        {props.children}
      </div>
    </button>
  );
}
