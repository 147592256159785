import React from "react";
import "./Disclaimer.scss";

import { Copy } from "components/atoms/Copy/Copy";

function Disclaimer({ copy, textWidth }) {
  return (
    <div
      className="disclaimer"
      style={{
        gridColumn: `${26 / 2 - textWidth / 2} / span ${textWidth}`,
      }}
    >
      <Copy
        theme="main"
        colorRecipe="colorRecipe200"
        className="disclaimer__copy"
        fontSize="dynamic-0100"
        html={copy}
      />
    </div>
  );
}

export { Disclaimer };
