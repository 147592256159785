import React, { useContext } from "react";
import "./FullscreenStage.scss";

import { themes } from "helpers/theme-provider/definitions";

import { SitemapContext } from "AppRouter";

import {
  Button,
  ButtonAppearance,
  ButtonType,
} from "components/atoms/Button/Button";
import { Headline } from "components/atoms/Headline/Headline";
import { Copy } from "components/atoms/Copy/Copy";
import { Media } from "components/atoms/Media/Media";

function FullscreenStage({
  textWidth,
  textVerticalPosition,
  headline,
  headlineSize,
  subheadline,
  copy,
  mediaPath,
  media,
  mediaDisclaimer,
  to = 12,
  cta,
}) {
  const { flatSitemap } = useContext(SitemapContext);

  return (
    <div className="fullscreen-stage">
      <Media
        className="fullscreen-stage__bg"
        mediaPath={mediaPath}
        background={true}
        media={media}
        mediaDisclaimer={mediaDisclaimer}
      />

      <div
        className="fullscreen-stage__text"
        style={{
          gridColumn: "2 / " + (2 + parseInt(textWidth)),
          paddingBottom: 4.16 * parseInt(textVerticalPosition) + "vw"
        }}
      >
        <Headline
          theme="image"
          colorRecipe="colorRecipe400"
          className="fullscreen-stage__subheadline"
          html={subheadline}
          headlineSize={headlineSize}
        />

        <Headline
          theme="image"
          colorRecipe="colorRecipe100"
          className="fullscreen-stage__headline"
          html={headline}
          headlineSize={headlineSize}
        />

        <Copy
          theme="image"
          colorRecipe="colorRecipe100"
          className="fullscreen-stage__copy"
          fontSize="dynamic-0200"
          html={copy}
        />

        <Button
          appearance={ButtonAppearance.PRIMARY}
          theme={themes.IMAGE}
          type={ButtonType.LINK}
          to={`${flatSitemap[to].alias}`}
          fontSize="static-0200"
        >
          {cta}
        </Button>
      </div>
    </div>
  );
}

export { FullscreenStage };
