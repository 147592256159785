import React, { useContext, useEffect } from "react";
import { useFetch } from "react-async";
import { Link } from "react-router-dom";
import { getContentElementByType } from "helpers/contentElements";
import { GlobalStateContext } from "AppRouter";
import { SitemapContext } from "AppRouter";
import { HeaderTheme } from "components/molecules/Header/Header";

import "./OverviewPage.scss";
import { LoadingSpinner } from "components/atoms/LoadingSpinner/LoadingSpinner";

const Alignment = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
};

function OverviewPage({ pageId }) {
  const { setHeaderTheme, language, setBackbuttonVisible } = useContext(
    GlobalStateContext
  );
  const { flatSitemap } = useContext(SitemapContext);
  setHeaderTheme(HeaderTheme.DARK);

  // AJAX call
  const jsonUrl = process.env.REACT_APP_LOKAL_FETCH
      ? process.env.PUBLIC_URL + `/${language}/${pageId}.json`
      : `${process.env.REACT_APP_CONTAO_URL}${pageId}?language=${language}`;;

  const headers = { Accept: "application/json" };
  const { data, error, isLoading } = useFetch(jsonUrl, {
    headers,
  });

  // set up backbtn route
  useEffect(() => {
    if (data) {
      setBackbuttonVisible(true);
    }

    return () => {
      setBackbuttonVisible(false);
    };
  }, [data, setBackbuttonVisible]);

  if (isLoading) return <LoadingSpinner />;
  if (error) return `Something went wrong: ${error.message}`;

  if (data) {
    return (
      <div className="overview">
        {data.columns.map((column, idx) => {
          return (
            <div
              className="overview__column"
              key={"col" + idx}
              style={{ justifyContent: Alignment[column.alignment] }}
            >
              {column.content.map((contentEl, idx) => {
                const ContentElement = getContentElementByType(contentEl.type);
                try {
                  const subpageAlias = flatSitemap[contentEl.to].alias;

                  return (
                    <Link
                      to={`${subpageAlias}/${contentEl.articleId}`}
                      key={"contentEl" + idx}
                    >
                      <ContentElement {...contentEl} />
                    </Link>
                  );
                } catch (err) {
                  console.error(err.message);
                  return <ContentElement {...contentEl} />;
                }
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export { OverviewPage };
