import React from "react";
import { NaviLink } from "components/atoms/NaviLink/NaviLink";
import "./TabNavi.scss";

function TabNavi({ linklist, match }) {
  if (linklist) {
    return (
      <nav className="tab-navi">
        <ul>
          {Object.values(linklist)
            .sort((a, b) => a.sorting - b.sorting)
            .map(link => {
              return (
                <NaviLink
                  key={link.alias}
                  to={`${match.url}/${link.alias}`}
                  className="label"
                  fontSize="static-0400"
                  page={link}
                >
                  {link.label}
                </NaviLink>
              );
            })}
        </ul>
      </nav>
    );
  }
  return <div />;
}

export { TabNavi };
