import React, { useContext, useEffect } from "react";
import "./DetailPage.scss";
import { Route } from "react-router-dom";
import { useFetch } from "react-async";
import { getContentElementByType } from "helpers/contentElements";
import { NoMatch } from "components/pages/NoMatch/NoMatch";
import { GlobalStateContext } from "AppRouter";
import { HeaderTheme } from "components/molecules/Header/Header";
import { LoadingSpinner } from "components/atoms/LoadingSpinner/LoadingSpinner";

function DetailPage({ match, pageId }) {
  const {
    setHeaderTheme,
    setPageHasScrollbar,
    setBackbuttonVisible,
    language,
    setHeaderSolid,
    setContentPadded
  } = useContext(GlobalStateContext);
  setHeaderTheme(HeaderTheme.DARK);

  // AJAX call
  const jsonUrl = process.env.REACT_APP_LOKAL_FETCH
      ? process.env.PUBLIC_URL + `/${language}/${pageId}.json`
      : `${process.env.REACT_APP_CONTAO_URL}${pageId}?language=${language}`;

  const headers = { Accept: "application/json" };
  const { data, error, isLoading } = useFetch(jsonUrl, {
    headers
  });

  // set up backbtn route
  useEffect(() => {
    if (data) {
      setBackbuttonVisible(true);
      setHeaderSolid(true);
      setContentPadded(true);
    }

    return () => {
      setBackbuttonVisible(false);
      setHeaderSolid(false);
      setContentPadded(false);
    };
  }, [data, setBackbuttonVisible, setHeaderSolid, setContentPadded]);

  // set page has Scrollbars & cleanup on unmount
  useEffect(() => {
    setPageHasScrollbar(true);

    return () => {
      setPageHasScrollbar(false);
    };
  }, [setPageHasScrollbar]);

  if (isLoading) return <LoadingSpinner />;
  if (error) return `Something went wrong: ${error.message}`;

  if (data) {
    return (
      <Route
        path={`${match.path}/:id`}
        render={routerProps => {
          const detailId = String(routerProps.match.params.id);

          if (!data[detailId]) return <NoMatch />;

          return data[detailId].content.map((el, idx) => {
            const DynamicComponent = getContentElementByType(el.type);
            return <DynamicComponent {...el} key={"section" + idx} />;
          });
        }}
      />
    );
  }
}

export { DetailPage };
