const colors = {
  white000: "#ffffff",
  black000: "#000000",
  blue200: "#00b0f0",
  blue600: "#001e50",
  grey100: "#dfe4e8",
  grey200: "#c2cacf",
  grey300: "#96a3a8",
  grey400: "#6a767d",
  grey500: "#3c484d",
  blue500: "#00437a",
  red200: "#e4002c",
  green200: "#029640",
  yellow000: "#ffd100",
  mint100: "#00e6e6",
  purple100: "#5b08a4",
  blue100: "#4cc7f4",
  blue900: "#0040c5",
  red100: "#ff335c",
  green100: "#ade5a1",
  transparent: "transparent"
};

const themes = {
  MAIN: "main",
  INVERTED: "inverted",
  IMAGE: "image"
};

const recipes = {
  main: {
    colorRecipe100: {
      default: colors.black000,
      hover: colors.grey400,
      active: colors.grey400,
      disabled: colors.grey200,
      checked: colors.black000
    },
    colorRecipe150: {
      default: colors.grey100,
      hover: colors.grey100,
      active: colors.green100,
      disabled: colors.green100,
      checked: colors.blue600
    },
    colorRecipe200: {
      default: colors.grey400,
      hover: colors.grey300,
      active: colors.grey300,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe250: {
      default: colors.blue200,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey100,
      checked: colors.blue200
    },
    colorRecipe300: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey400,
      checked: colors.blue600
    },
    colorRecipe350: {
      default: colors.green200,
      hover: colors.green100,
      active: colors.green100,
      disabled: colors.green200,
      checked: colors.green200
    },
    colorRecipe360: {
      default: colors.red200,
      hover: colors.red100,
      active: colors.red100,
      disabled: colors.red200,
      checked: colors.red100
    },
    colorRecipe370: {
      default: colors.purple100,
      hover: colors.purple100,
      active: colors.purple100,
      disabled: colors.grey100,
      checked: colors.purple100
    },
    colorRecipe400: {
      default: colors.blue600,
      hover: colors.blue900,
      active: colors.blue900,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe450: {
      default: colors.blue600,
      hover: colors.blue900,
      active: colors.blue900,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe500: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe550: {
      default: colors.grey200,
      hover: colors.grey100,
      active: colors.grey100,
      disabled: colors.transparent,
      checked: colors.grey200
    },
    colorRecipe600: {
      default: colors.grey500,
      hover: colors.grey400,
      active: colors.grey400,
      disabled: colors.transparent,
      checked: colors.grey500
    },
    colorRecipe700: {
      default: colors.grey100,
      hover: colors.grey400,
      active: colors.grey400,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe750: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe800: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey200,
      checked: colors.blue600
    },
    colorRecipe850: {
      default: colors.black000,
      hover: colors.black000,
      active: colors.black000,
      disabled: colors.white000,
      checked: colors.white000
    },
    colorRecipe900: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey200,
      checked: colors.blue600
    },
    colorRecipe950: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.white000,
      checked: colors.white000
    }
  },

  inverted: {
    colorRecipe100: {
      default: colors.white000,
      hover: colors.grey200,
      active: colors.grey200,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe150: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey400,
      checked: colors.blue200
    },
    colorRecipe200: {
      default: colors.grey200,
      hover: colors.grey100,
      active: colors.grey100,
      disabled: colors.grey400,
      checked: colors.blue200
    },
    colorRecipe250: {
      default: colors.blue200,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey100,
      checked: colors.blue200
    },
    colorRecipe300: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey400,
      checked: colors.blue600
    },
    colorRecipe350: {
      default: colors.green200,
      hover: colors.green100,
      active: colors.green100,
      disabled: colors.green200,
      checked: colors.green200
    },
    colorRecipe360: {
      default: colors.red200,
      hover: colors.red100,
      active: colors.red100,
      disabled: colors.red200,
      checked: colors.red200
    },
    colorRecipe370: {
      default: colors.purple100,
      hover: colors.purple100,
      active: colors.purple100,
      disabled: colors.grey100,
      checked: colors.purple100
    },
    colorRecipe400: {
      default: colors.blue200,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey400,
      checked: colors.blue200
    },
    colorRecipe450: {
      default: colors.white000,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe500: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe550: {
      default: colors.grey200,
      hover: colors.grey100,
      active: colors.grey100,
      disabled: colors.transparent,
      checked: colors.grey200
    },
    colorRecipe600: {
      default: colors.grey500,
      hover: colors.grey400,
      active: colors.grey400,
      disabled: colors.transparent,
      checked: colors.grey500
    },
    colorRecipe700: {
      default: colors.grey400,
      hover: colors.grey200,
      active: colors.grey200,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe750: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe800: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey200,
      checked: colors.blue600
    },
    colorRecipe850: {
      default: colors.black000,
      hover: colors.black000,
      active: colors.black000,
      disabled: colors.white000,
      checked: colors.white000
    },
    colorRecipe900: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey200,
      checked: colors.blue600
    },
    colorRecipe950: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.white000,
      checked: colors.white000
    }
  },

  image: {
    colorRecipe100: {
      default: colors.white000,
      hover: colors.grey200,
      active: colors.grey200,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe150: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe200: {
      default: colors.grey200,
      hover: colors.grey100,
      active: colors.grey100,
      disabled: colors.grey400,
      checked: colors.blue200
    },
    colorRecipe250: {
      default: colors.blue200,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey100,
      checked: colors.blue200
    },
    colorRecipe300: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey400,
      checked: colors.blue600
    },
    colorRecipe350: {
      default: colors.green200,
      hover: colors.green100,
      active: colors.green100,
      disabled: colors.green200,
      checked: colors.green200
    },
    colorRecipe360: {
      default: colors.red200,
      hover: colors.red100,
      active: colors.red100,
      disabled: colors.red200,
      checked: colors.red200
    },
    colorRecipe370: {
      default: colors.purple100,
      hover: colors.purple100,
      active: colors.purple100,
      disabled: colors.grey100,
      checked: colors.purple100
    },
    colorRecipe400: {
      default: colors.white000,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe450: {
      default: colors.white000,
      hover: colors.blue100,
      active: colors.blue100,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe500: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe550: {
      default: colors.grey200,
      hover: colors.grey100,
      active: colors.grey100,
      disabled: colors.transparent,
      checked: colors.grey200
    },
    colorRecipe600: {
      default: colors.grey500,
      hover: colors.grey400,
      active: colors.grey400,
      disabled: colors.transparent,
      checked: colors.grey200
    },
    colorRecipe700: {
      default: colors.grey400,
      hover: colors.grey200,
      active: colors.grey200,
      disabled: colors.grey400,
      checked: colors.white000
    },
    colorRecipe750: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey100,
      checked: colors.blue600
    },
    colorRecipe800: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.grey200,
      checked: colors.blue600
    },
    colorRecipe850: {
      default: colors.black000,
      hover: colors.black000,
      active: colors.black000,
      disabled: colors.white000,
      checked: colors.white000
    },
    colorRecipe900: {
      default: colors.blue600,
      hover: colors.blue600,
      active: colors.blue600,
      disabled: colors.grey200,
      checked: colors.blue600
    },
    colorRecipe950: {
      default: colors.white000,
      hover: colors.white000,
      active: colors.white000,
      disabled: colors.white000,
      checked: colors.white000
    }
  }
};

export { colors, themes, recipes };
