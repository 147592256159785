import React from "react";
import "./EditorialStage.scss";

import { Media } from "components/atoms/Media/Media";
import { Headline } from "components/atoms/Headline/Headline";
import { Copy } from "components/atoms/Copy/Copy";

import { WormyAnimation } from "components/atoms/WormyAnimation/WormyAnimation";

function EditorialStage({
  headline,
  headlineSize,
  subheadline,
  copy,
  mediaDisclaimer,
  textPosition = "left",
  media,
  mediaPath,
  wormyAnimation,
}) {
  return (
    <>
      <div className="editorial-stage">
        <div
          className={`editorial-stage__text editorial-stage__text--${textPosition}`}
        >
          {subheadline && (
            <Headline
              theme="main"
              colorRecipe="colorRecipe400"
              className="editorial-stage__subheadline"
              html={subheadline}
              headlineSize={headlineSize}
            />
          )}
          {headline && (
            <Headline
              theme="main"
              colorRecipe="colorRecipe100"
              className="editorial-stage__headline"
              html={headline}
              headlineSize={headlineSize}
              fontSize="dynamic-0500"
            />
          )}
          {copy && (
            <Copy
              theme="main"
              colorRecipe="colorRecipe100"
              className="editorial-stage__copy"
              fontSize="dynamic-0300"
              html={copy}
            />
          )}
          {wormyAnimation && <WormyAnimation />}
        </div>
        <Media
          className="editorial-stage__media-container"
          mediaPath={mediaPath}
          background={false}
          mediaDisclaimer={mediaDisclaimer}
          media={media}
        />
      </div>
    </>
  );
}

export { EditorialStage };
