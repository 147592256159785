import React from "react";
import "./Iframe.scss";

function Iframe({ target }) {
  return (
    <div className="iframe">
      <iframe src={target} title="myFrame" frameBorder="0" />
    </div>
  );
}

export { Iframe };
