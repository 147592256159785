import React, { useState, useEffect, useReducer, useContext } from "react";
import { useFetch } from "react-async";
import { CSSTransition } from "react-transition-group";

import { GlobalStateContext } from "AppRouter";
import { HeaderTheme } from "components/molecules/Header/Header";
import { Hotspot } from "components/molecules/Hotspot/Hotspot";
import { Toggle } from "components/atoms/Toggle/Toggle";
import { Media } from "components/atoms/Media/Media";
import {
  InteractionLayer,
  InteractionLayerSize
} from "components/atoms/InteractionLayer/InteractionLayer";

import "./InteractiveMapPage.scss";
import { LoadingSpinner } from "components/atoms/LoadingSpinner/LoadingSpinner";
import { getContentElementByType } from "helpers/contentElements";

function InteractiveMapPage({ pageId }) {
  const { setHeaderTheme, language } = useContext(GlobalStateContext);

  // AJAX call
  const jsonUrl =  process.env.REACT_APP_LOKAL_FETCH
      ? process.env.PUBLIC_URL + `/${language}/${pageId}.json`
      : `${process.env.REACT_APP_CONTAO_URL}${process.env.REACT_APP_MAP_ID}?language=${language}`;

  const headers = { Accept: "application/json" };
  const { data, error, isLoading } = useFetch(jsonUrl, {
    headers
  });

  // -----------------------
  // State
  // -----------------------
  setHeaderTheme(HeaderTheme.LIGHT);

  const [activeLvl, setActiveLvl] = useState("");
  const [scaleMap, setsetScaleMap] = useState(false);
  const [activeHotspotIdx, setActiveHotspotIdx] = useState(null);
  const [activeHotspot, setActiveHotspot] = useState(null);

  const [levelMap, dispatch] = useReducer((arr, { action, value }) => {
    switch (action) {
      case "ADD":
        return [...arr, ...value];

      case "TOGGLE":
        const tmpArr = [...arr];
        const tmpVal = tmpArr.pop();
        tmpArr.unshift(tmpVal);
        return tmpArr;

      default:
        return arr;
    }
  }, []);

  // -----------------------
  // useEffects
  // -----------------------
  // const hideToggle = process.env.REACT_APP_MAP_HIDE_TOGGLE;
  // console.log("hideToggle = " + hideToggle);

  // set initial active level
  useEffect(() => {
    if (data) {
      const tmpArr = Object.keys(data.levels).map(levelKey => levelKey);
      dispatch({ action: "ADD", value: tmpArr });
    }
  }, [data]);

  // set active level
  useEffect(() => {
    levelMap.length && setActiveLvl(levelMap[0]);
  }, [levelMap]);

  // -----------------------
  // Handler
  // -----------------------

  function handleToggle() {
    dispatch({ action: "TOGGLE" });
  }

  function handleHotspotClick(hotspot, idx) {
    setActiveHotspotIdx(idx);
    setActiveHotspot(hotspot);
    setsetScaleMap(true);
  }

  function onLayerClose() {
    setsetScaleMap(false);
    setActiveHotspotIdx(null);
    setActiveHotspot(null);
  }

  function onOverlayClick() {
    setsetScaleMap(false);
    setActiveHotspotIdx(null);
    setActiveHotspot(null);
  }

  function onMapScaleEnter(node) {
    const contentElWidth = document.querySelector("#content").clientWidth;
    const layerWidth = document.querySelector(".interaction-layer").clientWidth;
    const visibleSpace = contentElWidth - layerWidth;

    node.style.setProperty(
      `--offsetLeft`,
      `calc(-${activeHotspot.x}% + ${visibleSpace / 2}px)`
    );

    node.style.setProperty(`--offsetTop`, `calc(50% - ${activeHotspot.y}%)`);
  }

  // -----------------------
  // render
  // -----------------------

  if (isLoading) return <LoadingSpinner />;
  if (error) return `Something went wrong: ${error.message}`;

  if (data) {
    const toggleOptions = Object.keys(data.levels).map(levelKey => {
      return { value: levelKey, label: data.levels[levelKey].label };
    });

    return (
      <>
        <div className="map-bg" />
        <div className="map">
          {Object.keys(data.levels).map(levelKey => {
            const level = data.levels[levelKey];

            return (
              <div
                className={`map__lvl
                ${activeLvl === levelKey ? "map__lvl--active" : ""}`}
                key={levelKey}
              >
                <div className="map__aspectratio-wrapper">
                  <CSSTransition
                    in={scaleMap && activeLvl === levelKey}
                    timeout={2000}
                    classNames="scale"
                    onEnter={node => onMapScaleEnter(node)}
                  >
                    <div className="map__aspectratio-inner">
                      <Media mediaPath={level.bg} className="map__image" />

                      {level.hotspots.map((hotspot, idx) => (
                        <Media
                          mediaPath={hotspot.mediaPath}
                          className={`map__hotspot-image ${
                            idx === activeHotspotIdx
                              ? "map__hotspot-image--visible"
                              : ""
                          }`}
                          key={"hotspotimage" + idx}
                        />
                      ))}

                      {level.hotspots.map((hotspot, idx) => (
                        <Hotspot
                          {...hotspot}
                          key={"hotspot" + idx}
                          onClickHandler={e => handleHotspotClick(hotspot, idx)}
                          className={
                            typeof activeHotspotIdx === "number" &&
                            idx !== activeHotspotIdx
                              ? "hotspot--hidden"
                              : ""
                          }
                        />
                      ))}
                    </div>
                  </CSSTransition>
                </div>
              </div>
            );
          })}
        </div>

        <Toggle
          cb={handleToggle}
          options={toggleOptions}
          className={`map__toggle ${
            activeHotspot || process.env.REACT_APP_MAP_HIDE_TOGGLE
              ? "map__toggle--hidden"
              : ""
          }`}
        />

        {activeHotspot && (
          <div className="map__overlay" onClick={onOverlayClick} />
        )}
        <InteractionLayer
          active={!!activeHotspot}
          onCloseCb={onLayerClose}
          size={InteractionLayerSize.D}
          className="map__layer"
        >
          {activeHotspot &&
            activeHotspot.content.map((contentEl, idx) => {
              const ContentComponent = getContentElementByType(contentEl.type);
              return (
                <ContentComponent
                  {...contentEl}
                  key={"contentEl" + idx}
                  isInLayer
                />
              );
            })}
        </InteractionLayer>
      </>
    );
  }
}

export { InteractiveMapPage };
