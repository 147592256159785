import React from "react";
import "./InteractionLayer.scss";
import { IconClose } from "../IconClose";

const InteractionLayerSize = {
  A: "a",
  B: "b",
  C: "c",
  D: "d"
};

function InteractionLayer({
  size = InteractionLayerSize.A,
  active = false,
  onCloseCb,
  children,
  className
}) {
  function closeHandler() {
    active = false;

    if (typeof onCloseCb === "function") {
      onCloseCb();
    }
  }

  return (
    <div className={`interaction-layer__wrapper ${className}`}>
      <div
        className={`interaction-layer interaction-layer--${size} ${
          active ? "interaction-layer--active" : ""
        }`}
      >
        <div className="interaction-layer__close-btn" onClick={closeHandler}>
          <IconClose variant="default" />
        </div>
        <div className="interaction-layer__inner">{children}</div>
      </div>
    </div>
  );
}

export { InteractionLayer, InteractionLayerSize };
