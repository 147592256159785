import React from "react";
import "./TextWithMedia.scss";

import { Media } from "components/atoms/Media/Media";
import { Headline } from "components/atoms/Headline/Headline";
import { Copy } from "components/atoms/Copy/Copy";

function TextWithMedia({
  textWidth,
  textPosition,
  textVerticalAlign,
  headline,
  headlineSize,
  subheadline,
  copy,
  media,
  mediaPath,
  mediaDisclaimer,
  mediaWidth = 21 - parseInt(textWidth),
  playWhenInview = false,
  isInLayer = false
}) {
  if (!isInLayer) {
    return (
      <div className="text-with-media">
        <div
          className={`text-with-media__text text-with-media__text--${textVerticalAlign}`}
          style={{
            gridColumn: `${
              textPosition === "left"
                ? `2 / span ${textWidth}`
                : `-${parseInt(textWidth) + 2} / span ${textWidth}`
            }`,
            gridRow: "1 / 2"
          }}
        >
          {subheadline && (
            <Headline
              theme="main"
              colorRecipe="colorRecipe400"
              className="text-with-media__subheadline"
              html={subheadline}
              fontSize="dynamic-0300"
            />
          )}
          {headline && (
            <Headline
              theme="main"
              colorRecipe="colorRecipe100"
              className="text-with-media__headline"
              html={headline}
              headlineSize={headlineSize}
            />
          )}
          {copy && (
            <Copy
              theme="main"
              colorRecipe="colorRecipe100"
              className="text-with-media__copy"
              fontSize="dynamic-0250"
              html={copy}
            />
          )}
        </div>
        <div
          className={`text-with-media__media`}
          style={{
            gridColumn: `${
              textPosition === "left"
                ? `${parseInt(textWidth) + 3} / span ${mediaWidth}`
                : `2 / span ${mediaWidth}`
            }`,
            gridRow: "1 / 2"
          }}
        >
          <Media
            mediaPath={mediaPath}
            media={media}
            mediaDisclaimer={mediaDisclaimer}
            playWhenInview={playWhenInview}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="text-with-media text-with-media--layer">
      {subheadline && (
        <Headline
          theme="main"
          colorRecipe="colorRecipe400"
          className="text-with-media__subheadline"
          html={subheadline}
          fontSize="dynamic-0300"
        />
      )}
      {headline && (
        <Headline
          theme="main"
          colorRecipe="colorRecipe100"
          className="text-with-media__headline"
          html={headline}
          headlineSize={headlineSize}
        />
      )}
      <div className={`text-with-media__text`}>
        {copy && (
          <Copy
            theme="main"
            colorRecipe="colorRecipe100"
            className="text-with-media__copy"
            fontSize="dynamic-0250"
            html={copy}
          />
        )}

        <div className={`text-with-media__media`}>
          <Media
            mediaPath={mediaPath}
            media={media}
            mediaDisclaimer={mediaDisclaimer}
          />
        </div>
      </div>
    </div>
  );
}

export { TextWithMedia };
