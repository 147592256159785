import React from "react";
function IconPause(props) {
  if (props.variant === "default") {
    return React.createElement(
      "svg",
      {
        title: props.title,
        viewBox: "0 0 24 24",
        width: props.width || "24",
        height: props.height || "24",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        role: "img"
      },
      React.createElement("path", {
        d: "M6.835 4.5h1.33v15h-1.33zM15.835 4.5h1.33v15h-1.33z"
      })
    );
  }
  return null;
}

export { IconPause };
