import React from "react";
function IconPlay(props) {
  if (props.variant === "default") {
    return React.createElement(
      "svg",
      {
        title: props.title,
        viewBox: "0 0 24 24",
        width: props.width || "24",
        height: props.height || "24",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        role: "img"
      },
      React.createElement("path", {
        d:
          "M6.33 5.412L17.516 12 6.33 18.588zm-.133-1.433c-.202 0-.404.053-.589.159A1.2 1.2 0 005 5.194v13.613a1.202 1.202 0 001.197 1.215c.206 0 .413-.055.6-.165l11.605-6.835c.38-.224.598-.596.598-1.022s-.217-.798-.597-1.021L6.797 4.144a1.185 1.185 0 00-.6-.165z"
      })
    );
  }
  return null;
}

export { IconPlay };
