// Atoms
import { Button } from "components/atoms/Button/Button";
import { Headline } from "components/atoms/Headline/Headline";
import { Media } from "components/atoms/Media/Media";
import { Copy } from "components/atoms/Copy/Copy";

// Molecules
import { EditorialStage } from "components/molecules/EditorialStage/EditorialStage";
import { EditorialTeaser } from "components/molecules/EditorialTeaser/EditorialTeaser";
import { MediaOnly } from "components/molecules/MediaOnly/MediaOnly";
import { TextOnly } from "components/molecules/TextOnly/TextOnly";
import { TextWithMedia } from "components/molecules/TextWithMedia/TextWithMedia";
import { Disclaimer } from "components/atoms/Disclaimer/Disclaimer";
import { Iframe } from "components/atoms/Iframe/Iframe";
import { NoMatch } from "components/pages/NoMatch/NoMatch";

// Organisms

// Pages

const contentElementTypes = {
  BUTTON: "button",
  HEADLINE: "headline",
  MEDIA: "media",
  COPY: "copy",
  EDITORIALSTAGE: "editorialStage",
  EDITORIALTEASER: "editorialTeaser",
  MEDIAONLY: "mediaOnly",
  TEXTONLY: "textOnly",
  TEXTWITHMEDIA: "textWithMedia",
  DISCLAIMER: "disclaimer",
  IFRAME: "iframe"
};

function getContentElementByType(type) {
  switch (type) {
    case contentElementTypes.BUTTON:
      return Button;
    case contentElementTypes.HEADLINE:
      return Headline;
    case contentElementTypes.MEDIA:
      return Media;
    case contentElementTypes.COPY:
      return Copy;
    case contentElementTypes.EDITORIALSTAGE:
      return EditorialStage;
    case contentElementTypes.EDITORIALTEASER:
      return EditorialTeaser;
    case contentElementTypes.MEDIAONLY:
      return MediaOnly;
    case contentElementTypes.TEXTONLY:
      return TextOnly;
    case contentElementTypes.TEXTWITHMEDIA:
      return TextWithMedia;
    case contentElementTypes.DISCLAIMER:
      return Disclaimer;
    case contentElementTypes.IFRAME:
      return Iframe;
    default:
      return NoMatch;
  }
}

export { getContentElementByType };
