import React from "react";
import "./TextOnly.scss";

import { Headline } from "components/atoms/Headline/Headline";
import { Copy } from "components/atoms/Copy/Copy";

function TextOnly({
  headline,
  headlineSize,
  subheadline,
  copy,
  textWidth,
  indentation,
  mediaDisclaimer,
}) {
  return (
    <div
      className={`text-only ${indentation ? "text-only--indented" : ""}`}
      style={{
        gridColumn: `${26 / 2 - textWidth / 2} / span ${textWidth}`,
      }}
    >
      {subheadline && (
        <Headline
          theme="main"
          colorRecipe="colorRecipe400"
          className="text-only__subheadline"
          html={subheadline}
          fontSize="dynamic-0300"
        />
      )}
      {headline && (
        <Headline
          theme="main"
          colorRecipe="colorRecipe100"
          className="text-only__headline"
          html={headline}
          headlineSize={headlineSize}
        />
      )}
      {copy && (
        <Copy
          theme="main"
          colorRecipe="colorRecipe100"
          className="text-only__copy"
          fontSize="dynamic-0250"
          html={copy}
        />
      )}
      {mediaDisclaimer && (
        <Copy
          theme="main"
          colorRecipe="colorRecipe200"
          className="text-only__disclaimer"
          fontSize="dynamic-0100"
          html={mediaDisclaimer}
        />
      )}
    </div>
  );
}

export { TextOnly };
