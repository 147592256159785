import React from "react";
function IconArrowLeft(props) {
  if (props.variant === "default") {
    return React.createElement(
      "svg",
      {
        title: props.title,
        height: "24",
        viewBox: "0 0 24 24",
        width: "24",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        role: "img",
      },
      React.createElement("path", {
        d:
          "M4.065 11.62H22v1.33H4.103c.159.165.34.348.541.549.947.948 2.336 2.275 4.241 4.051l-.913.981C3.732 14.583 2 12.897 2 12.265 2 11.634 3.732 9.948 7.972 6l.913.981c-1.905 1.776-3.294 3.103-4.241 4.051-.218.217-.41.413-.579.588z",
      })
    );
  }
  if (props.variant === "small") {
    return React.createElement(
      "svg",
      {
        title: props.title,
        height: "12",
        viewBox: "0 0 12 12",
        width: "12",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        role: "img",
      },
      React.createElement("path", {
        d:
          "M2.603 5.5H11v1H2.603a93.76 93.76 0 002.238 2.134l-.682.732C1.653 7.033 1 6.38 1 6s.653-1.033 3.16-3.366l.68.732A93.764 93.764 0 002.605 5.5z",
      })
    );
  }
  return null;
};

export {IconArrowLeft};